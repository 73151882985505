body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.form-campo {
  display: flex;
  flex-direction: row;
  margin-bottom: 12px;
}

.label-campo {
  margin-top: auto;
  margin-bottom: auto;
  width: 200px;
}

.card {
  width: 25%;
  margin: 20px;
  border-radius: 6px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.2);
}

.card:hover {
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.4);
  transform: translateY(-3%);
}

.card img {
  width: 100%;
  height: 210px;
}
.container-card {
  display: flex;

  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
